//FS Albert
@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-ExtraBold.ttf");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-Bold.ttf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-BoldItalic.ttf");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "FS Albert";
	font-weight: 500;
	font-style: normal;
	src: url("../fonts/FSAlbert-ttf/FSAlbert.ttf");
}
@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-Italic.ttf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-Light.ttf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-LightItalic.ttf");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-Thin.ttf");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "FS Albert";
	src: url("../fonts/FSAlbert-ttf/FSAlbert-ThinItalic.ttf");
	font-weight: 100;
	font-style: italic;
}

//Gotham

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Ultra.otf");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-UltraItalic.otf");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Black.otf");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-BlackItalic.otf");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Bold.otf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-BoldItalic.otf");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Medium.otf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-MediumItalic.otf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Book.otf");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-BookItalic.otf");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Light.otf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-LightItalic.otf");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-XLight.otf");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-XLightItalic.otf");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-Thin.otf");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Gotham";
	src: url("../fonts/Gotham-otf/Gotham-ThinItalic.otf");
	font-weight: 100;
	font-style: italic;
}

//Gotham Rounded

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-Bold.otf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-BoldIta.otf");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-Medium.otf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-MediumIta.otf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-Book.otf");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-BookIta.otf");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-Light.otf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Rounded";
	src: url("../fonts/Gotham-otf/GothamRnd-LightIta.otf");
	font-weight: 300;
	font-style: italic;
}
