@import "variables.scss";
@import "fonts.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	-webkit-text-fill-color: unset;
	-webkit-box-shadow: 0 0 0px 1000px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
}

body,
html,
#root {
	margin: 0;
	padding: 0;
}

#root {
	background: $PrimaryColor;
}

.ceabs-layout {
	.date-field {
		position: relative;
		display: flex;

		align-items: center;
		justify-items: center;

		input[type="date"] {
			flex: 1;
			outline: none !important;
			font-size: 400;
			background-color: transparent;
			z-index: 3;
		}

		input[type="datetime-local"] {
			flex: 1;
			outline: none !important;
			font-size: 400;
			background-color: transparent;
			z-index: 3;
		}

		input[type="date"]::-webkit-calendar-picker-indicator {
			opacity: 0;
			background-color: transparent;
			cursor: pointer;
		}

		input[type="datetime-local"]::-webkit-calendar-picker-indicator {
			opacity: 0;
			background-color: transparent;
			cursor: pointer;
		}

		& > img {
			margin-top: 1rem;
			margin-left: -1rem;
			width: 1rem;
			height: 1rem;
			z-index: 1;
		}

		&.disabled {
			& > img {
				filter: grayscale(100%) opacity(50%);
			}
		}

		.MuiInput-underline.Mui-disabled:before {
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
		}

		&.not-value {
			.MuiInput-underline.Mui-disabled:before {
				border: none;
			}
		}

		padding-right: 0.5rem;
	}

	.no-link {
		text-decoration: none;
		color: inherit;
	}

	.MuiButtonBase-root {
		text-transform: capitalize;
	}

	.MuiButton-outlined.MuiButton-outlinedSecondary {
		border-radius: 5px;
		border: 1px solid $PrimaryColor;
	}

	.ceabs-box-export-buttons {
		// padding: 2rem 0;

		button {
			margin-right: 1rem;
			height: 35px;
			border-radius: 5px;
			font-size: 14px;
			line-height: 19px;
			font-weight: 400;
		}
	}

	.ceabs-remove-buttons {
		button {
			margin-right: 1rem;
			height: 35px;
			border-radius: 5px;
			font-size: 14px;
			line-height: 19px;
			font-weight: 400;
			color: #e5441a;
			border: 1px solid #e5441a;
		}

		.MuiButton-outlined.MuiButton-outlinedSecondary {
			border-radius: 5px;
			border: 1px solid #e5441a;
		}
	}

	.ceabs-break-long-text {
		display: block;
		word-wrap: break-word;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 18rem;
	}

	.input-ready-only {
		.MuiInputBase-root {
			color: #000;

			&.MuiInput-underline.Mui-disabled:before {
				border: none !important;
			}

			img {
				display: none;
			}

			background-color: transparent;
		}
	}

	.ceabs-card.MuiPaper-root {
		box-shadow: 0px 1px 6px #00000029;
		border-radius: 7px;
		outline: none;
		border: none;

		form {
			padding: 0.5rem;
		}

		.MuiGrid-root {
			padding: 0.3rem 0.5rem;

			&.MuiGrid-container {
				padding: 0.5rem;
			}
		}

		.MuiLinearProgress-root {
			border-bottom-left-radius: 70px;
		}
	}

	.ceabs-card-title .MuiTypography-root {
		font-size: 16px;
		line-height: 27px;
		font-weight: normal;

		letter-spacing: 0px;
		color: #bfbfbf;
	}

	.ceabs-center-button {
		display: flex;
		align-items: center;
		justify-content: center;

		.MuiButtonBase-root {
			margin: 2rem;
			width: 130px;
			height: 40px;

			font-size: 15px;
			line-height: 19x;
			font-weight: normal;
			text-transform: capitalize;
		}
	}

	.ceabs-comeback-header {
		align-items: center;
		padding-bottom: 0rem;

		button {
			padding-bottom: 1rem;
		}

		.MuiTypography-root {
			padding-left: 0.5rem;
			font-size: 33px;
			line-height: 50px;
			letter-spacing: 0px;
			color: #bfbfbf;
			font-weight: normal;
			padding-bottom: 0.3rem;
		}
	}

	.ceabs-form {
		&.ceabs-card.MuiPaper-root {
			padding: 1.3rem;
			padding-bottom: 2rem;
		}

		.ceabs-form-title {
			.MuiTypography-root {
				font-size: 20px;
				font-weight: normal;
				color: #a7a7a7;
			}
		}

		.MuiFormLabel-root {
			margin-top: 0.1rem;
		}
	}

	.ceabs-box-form-action-buttons {
		padding: 2rem 0;

		button {
			margin-right: 1rem;
			width: 111px;
			height: 35px;
			border-radius: 5px;
			font-size: 14px;
			line-height: 19px;
			font-weight: 400;
		}
	}

	.ceabs-come-back-button {
		img {
			margin-right: 0.5rem;
			height: 8px;
		}

		.MuiButton-label {
			font-size: 16px;
			line-height: 29px;
		}
	}

	.label-radio.MuiFormLabel-root {
		font-size: 11px;
		margin-top: 0.3rem;
	}

	.radio-field {
		color: #777;
		font-size: 13px;

		.MuiTypography-root.MuiFormControlLabel-label {
			font-size: 13px;
		}
	}

	.ceabs-table {
		.MuiTableHead-root {
			background-color: #bfbfbf;
			.MuiTableCell-root {
				text-transform: capitalize;
				color: #fff;
				padding-top: 0.8rem;
				padding-bottom: 0.8rem;
			}
		}

		.MuiTableBody-root {
			.MuiTableRow-root {
				transition: 0.1s ease-out;

				&:hover {
					transition: 0.1s ease-out;
					background-color: #fff;
					cursor: pointer;
				}

				&.info-row {
					padding: 0;
					&:hover.MuiTableRow-root {
						background-color: transparent;
					}

					.MuiTableCell-root {
						padding: 0;
					}
				}
			}
		}
	}
}

.Toastify__toast-container {
	@media (min-width: 480px) {
		.Toastify__toast {
			border-radius: 5px;
			box-shadow: 0px 0px 8px 3px rgba($color: #000000, $alpha: 0.7);
			font: Regular 16px/19px FS Albert, sans-serif;
			white-space: pre-line;
			width: 300px;
			background: #{$PrimaryColor};
		}
	}
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #cccccc;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background: #777777;
}
::-webkit-scrollbar-track-piece:end {
	background: transparent;
	margin-bottom: 15px;
}

::-webkit-scrollbar-track-piece:start {
	background: transparent;
	margin-top: 15px;
}
