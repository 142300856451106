@import "../../../assets/style/";

.ceabs-layout {
	display: flex;
	flex-direction: column;
	height: 100vh;
	align-content: stretch;

	.ceabs-main {
		flex: 1;
		display: flex;
		flex-direction: row;

		.ceabs-content {
			flex: 1;
			background-color: #fff;
			padding: 2rem;

			.MuiContainer-root {
				padding: 0;
			}
		}
	}

	.ceabs-header {
		background-color: #fff;
		color: #{$PrimaryColor};
		box-shadow: none;

		.MuiToolbar-root {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #c7c7c7;

			.logo-container {
				flex: 0.15;
				padding: 25px;
				.logo {
					height: 30px;
				}
				img {
					height: 35px;
				}
			}

			.header-title {
				flex: 8;
				font-size: 28px;
				font-family: FS Albert;
			}

			.profile {
				display: flex;
				flex-direction: row;
				flex-grow: 0.5;
			}

			.username-container {
				align-self: center;
				.username {
					font-size: 15px;
					padding: 0 0.8rem;
				}
			}

			.icon-notification {
				border-right: 1px solid #c7c7c7;
			}
		}
	}
}

.traceable-table {
	table-layout: fixed;
}

.background-image-dog-z {
	background-image: url("../../../assets/i/dog-z.png");
	background-repeat: no-repeat;
	background-size: auto;
	background-position-y: bottom;
	background-position-x: right;
}

.background-image-curve {
	background-image: url("../../../assets/i/curve.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
}

.background-image-dog-interjection {
	background-image: url("../../../assets/i/dog-interjection.png");
	background-repeat: no-repeat;
	background-size: initial;
	background-position-y: bottom;
	background-position-x: right;
}

.background-image-curve-2 {
	background-image: url("../../../assets/i/curve-2.svg");
	background-repeat: no-repeat;
	background-position-x: right;
	background-attachment: fixed;
	background-size: contain;
}

.background-image-curve-portal {
	background-image: url("../../../assets/i/portal.svg");
	background-repeat: no-repeat;
	background-position-x: left;
	background-attachment: fixed;
	background-size: contain;
}

.remove-ppading {
	padding: 0 !important;
}

.navigation-menu {
	display: flex;
}
.navigation-menu-buttons {
}
