.autocomplete-final-adorment {
	margin: 0;
	padding: 0;

	.MuiChip-root {
		border-radius: 5px;
	}
}

.checkbox-combo {
	height: 3.875rem;
	margin-right: 0rem;
}

.MuiFormControlLabel-root.checkbox-true {
	background-color: #00000014 !important;
	margin-right: 0rem;
}
.checkbox-combo:hover {
	background-color: #0000000A;
	margin-right: 0rem;	
}

.checkbox-div {
	// display: flex;
	// justify-content: flex-start;
	// align-items: center;
	// flex-direction: row;
	padding-left: 2rem;
}
