@import "../../../assets/style/";

.ceabs-dialog {

	.MuiDialogTitle-root{
		color: #{$PrimaryColor};
	}

	.MuiDialog-paper {
		.MuiDialogActions-root {
			.ok-button {
				color: #{$PrimaryColor};
			}

			.cancel-button {
				color: #6f6f6f;
			}
		}
	}

	.close-button {
		position: absolute;
		top: 0;
		right: 0;
	}
}
